export const Color = {
  primaryLight: '#c1e3cb',
  primary: '#9cc0aa',
  primaryDark: '#7fa18c',
  secondaryLight: '#face93',
  secondary: '#f0b66a',
  secondaryDark: '#cc9854',
  tertiaryLight: '#f5cab3',
  tertiary: '#f0b08d',
  tertiaryDark: '#d19473',
  black: '#111111',
  gray: '#454c4d',
  midGray: '#888888',
  lightGray: '#c5c7c5',
  light: '#fdfaf0',
  white: '#fafafa',
  red: '#f04622 ',
  redDark: '#d13413 ',
};
